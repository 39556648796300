import Vue from 'vue'
import App from './App'
import router from './router'
import Axios from "axios"
import Util from './lib/utils.js'
import VueAxios from 'vue-axios'
import i18n from './lang/lang'
import Qs from 'qs'
import "@/assets/style/common.scss"
import VueSocketIO from 'vue-socket.io'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import store from './store'
import VueCookies from 'vue-cookies'



Vue.use(VueCookies)
Vue.use(VueAwesomeSwiper)

window.eventBus = new Vue()
/**
 * 配置接口地址和socket地址
 */
//let [bus_host, socket_api, socket_path] = ['http://154.221.21.165:8070', 'ws://154.221.21.165:2000', '/socket.io/'];
let [bus_host, socket_api, socket_path] = ['https://bitce.pro/', 'wss://bitce.site:2000', '/socket.io/'];

if (process.env.NODE_ENV === 'development') {
	bus_host = 'https://bitce.pro/';
	socket_api = 'wss://bitce.site';
	socket_path = '/socket.io/';
} else {

    bus_host = 'https://bitce.pro/';
    socket_api = 'wss://bitce.site';
    socket_path = '/socket.io/';

}

Vue.use(new VueSocketIO({
    debug: false,
    connection: socket_api,
    options: {path: socket_path} //Optional options
}))


Vue.config.productionTip = false


Axios.interceptors.request.use(function (config) {
    // layer.load(1);
    if (config.url.indexOf("transaction_in") < 0) {
        // layer.load(1);
    }
    config.url = bus_host + config.url;
    if (config.url.indexOf('?') === -1) {
        config.url = config.url + '?_timespan=' + (new Date()).getTime()
    } else {
        config.url = config.url + '&_timespan=' + (new Date()).getTime()
    }
    // 在发送请求之前做些什么
    return config
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})
Axios.interceptors.response.use(function (response) {
	let nowa=new Date().getTime()/1000;
	if(nowa>0){
		//return false;
	}
    layer.closeAll('loading');
	let lan=localStorage.getItem("locale");
    if (response.data.type == '999') {
		if(response.data.message=="请登录"){
			let msg="请登录";
			switch(lan){
				case "hk":
					msg="請登錄";
					break;
				case "en":
					msg="please sign in";
					break;
				case "kr":
					msg="로그인 해주세요";
					break;
				case "jp":
					msg="サインインしてください";
					break;
				case "spa":
					msg="Por favor, inicie sesión";
					break;
				default :
					msg="请登录";
					break
			}
			layer.msg(msg);
			localStorage.removeItem('user_id');
			localStorage.removeItem('token');
			
			setTimeout(() => {
			    //router.push("/components/login");
				window.location.href="/#/components/login"
			}, 500);
			return;
		}
		let msg="登录过时，请重新登录";
		
		switch(lan){
			case "hk":
				msg="登錄過時，請重新登錄";
				break;
			case "en":
				msg="Logon is out of date, please login again";
				break;
			case "kr":
				msg="로그인이 오래되었습니다. 다시 로그인하십시오.";
				break;
			case "jp":
				msg="ログインが古くなっています。もう一度ログインしてください";
				break;
			case "spa":
				msg="Login obsoleto, por favor regístrese de nuevo";
				break;
			default :
				msg="登录过时，请重新登录";
				break
		}
        layer.msg(msg);
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');

        setTimeout(() => {
            router.push("/login");
        }, 500);
    }
	if(response.data.type=="error"){
		let l1="账号锁定中,";
		let l3="前不能进行此操作";
		let ms=response.data.message;
		let lan=localStorage.getItem("locale");
		let msg="";
		let date="";
		if(ms.indexOf(l1)!=-1){
			date=ms.replace(l1,"");
			date=ms.replace(l3,"");
			switch(lan){
				case "hk":
					msg="賬號鎖定中,"+date+"前不能進行此操作";
					break;
				case "en":
					msg="Account is locked,Can't do this before "+date;
					break;
				case "kr":
					msg="계정이 잠겨 있습니다,"+date+"전에는 할 수 없습니다";
					break;
				case "jp":
					msg="アカウントはロックされています,"+date+"以前はこれを行うことはできません";
					break;
				default :
					msg="账号锁定中,"+date+"前不能進行此操作";
					break
			}
			layer.msg(msg);
		}
	}
    return response
})

Vue.config.productionTip = false;

Axios.defaults.transformRequest = [(data) => {
    if (data instanceof FormData) {
        return data;
    }
    return Qs.stringify(data)
}]

Vue.use(VueAxios, Axios);
Vue.use(Util);

let bus = new Vue()
Vue.prototype.bus = bus


import ElementUI from 'element-ui';

Vue.use(ElementUI)

Vue.config.devtools = true;

import 'element-ui/lib/theme-chalk/index.css'

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount("#app");
